import styled, { css } from "styled-components";
import Img from "gatsby-image";

import { device } from "../../../../../../../utils/breakpoints";
import {
  fadeIn,
  fadeOut,
  slideToRight,
  slideToLeft,
} from "../../../../../../../utils/animations";
import { PageChange } from "../../../../../../common/Scroll";
import {
  H3,
  H4,
  TEXT_BODY_2,
} from "../../../../../../../assets/styles/typography";

interface PageProps {
  animation?: PageChange;
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 112px 24px 32px 24px;
  background: #ffffff;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

export const TransitionWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(5, auto);
  z-index: 1000;
  pointer-events: none;

  .whiteBlock {
    animation: ${(props: PageProps) =>
      props.animation
        ? css`
            ${fadeIn} 300ms linear
          `
        : css`
            ${fadeOut} 300ms ease-in
          `};
    animation-fill-mode: forwards;
    animation-delay: ${(props: PageProps) => !props.animation && "0.7s"};

    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(11),
    &:nth-child(14),
    &:nth-child(19) {
      animation-delay: ${(props: PageProps) => !props.animation && "0.1s"};
    }

    &:nth-child(0),
    &:nth-child(1),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(13),
    &:nth-child(16) {
      animation-delay: ${(props: PageProps) => !props.animation && "0.4s"};
    }
  }
`;

export const TransitionBlock = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  margin-left: auto;
  background: #ffffff;
  z-index: 1;
`;

interface SlideTransitionProps {
  slideChange: PageChange;
}

export const ContentWrapper = styled.div`
  position: absolute;
  top: calc(40vh + 72px);
  width: 100%;
  flex: 1;
  height: calc(60vh - 72px - 48px);
  display: flex;
  flex-direction: column;
  margin-top: 48px;
`;

export const SlideTransitionWrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  margin-bottom: 32px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  animation: ${(props: SlideTransitionProps) =>
    props.slideChange
      ? css`
          ${props.slideChange === "next"
            ? slideToLeft
            : slideToRight} 300ms ease-in
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

export const Banner = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  animation: ${(props: SlideTransitionProps) =>
    props.slideChange
      ? css`
          ${props.slideChange === "next" ? fadeOut : fadeIn} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

export const BannerBackground = styled(Img)`
  position: absolute !important;
  top: 72px;
  left: 0;
  width: 100%;
  height: 40vh;

  img {
    object-position: 0 center !important;
  }
`;

export const Title = styled.h1`
  ${H3}
  margin: 0;
  color: #013cff;
`;

export const Subtitle = styled.h2`
  ${H4}
  margin-bottom: 24px;
  padding: 0;
  color: #013cff;

  @media ${device.mobileS} {
    font-size: 32px;
    line-height: 34px;
  } ;
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  width: 100%;
  margin: 0;
  padding: 24px 0 0 0;
  white-space: pre-line;
  color: #151515;
`;

export const SideImage = styled(Img)`
  width: 80%;
  transform: scale(1.2);
  margin: 0 auto;
  padding: 0 0 64px 0;

  img {
    object-fit: contain !important;
  }
`;

export const ArrowsWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(50%, 2);
`;

export const LeftArrowWrapper = styled.div`
  grid-column: 1;

  svg {
    animation: ${fadeIn} 300ms linear;
    ${(props: SlideTransitionProps) =>
      props.slideChange === "previous" &&
      css`
        animation: ${fadeOut} 300ms linear;
      `};
    animation-fill-mode: forwards;
  }
`;

export const RightArrowWrapper = styled.div`
  grid-column: 2;
  margin-left: auto;

  svg {
    animation: ${fadeIn} 300ms linear;
    ${(props: SlideTransitionProps) =>
      props.slideChange === "next" &&
      css`
        animation: ${fadeOut} 300ms linear;
      `};
    animation-fill-mode: forwards;
  }
`;
