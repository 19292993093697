import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  TransitionWrapper,
  TransitionBlock,
  ContentWrapper,
  SlideTransitionWrapper,
  Title,
  Subtitle,
  Description,
  Banner,
  BannerBackground,
  SideImage,
  ArrowsWrapper,
  LeftArrowWrapper,
  RightArrowWrapper,
} from "./style";
import { PageChange } from "../../../../../../common/Scroll";
import { PageThemeType } from "../../../../../../../utils/types";
import { fixConjunctions } from "../../../../../../../utils/dropTheWord";
import ArrowLeft from "-!svg-react-loader!../../../../../../../assets/images/arrowLeft.svg";
import ArrowRight from "-!svg-react-loader!../../../../../../../assets/images/arrowRight.svg";
import { PageTitle } from "../../../../../../common/PageTitle";

const transitionBlocks = [...Array(20)].map((_, index) => (
  <TransitionBlock key={index} className="whiteBlock" />
));

interface AITVStreamProps {
  pageChange: PageChange;
  pageThemeChange?: (theme?: PageThemeType) => void;
}

const AITVStreamMobile: React.FC<AITVStreamProps> = ({
  pageChange,
  pageThemeChange,
}) => {
  const nOfSlides = 2;
  const { t } = useTranslation("rnd");
  const { background, side } = useStaticQuery(imagesQuery);

  const [slide, setSlide] = useState<number>(
    pageChange === "previous" ? nOfSlides : 0
  );
  const [slideTransition, setSlideTransition] = useState<PageChange>();

  useEffect(() => {
    return () => {
      pageThemeChange && pageThemeChange(undefined);
    };
  }, []);

  const handlePrevious = () => {
    if (slide > 0) {
      setSlideTransition("previous");
      setTimeout(() => {
        const newSlide = slide - 1;
        setSlideTransition(undefined);
        setSlide(newSlide);
        newSlide > 0
          ? pageThemeChange && pageThemeChange("white")
          : pageThemeChange && pageThemeChange(undefined);
      }, 300);
    }
  };

  const handleNext = () => {
    if (slide < nOfSlides) {
      const newSlide = slide + 1;
      setSlideTransition("next");
      setTimeout(() => {
        setSlideTransition(undefined);
        setSlide(newSlide);
        newSlide > 0
          ? pageThemeChange && pageThemeChange("white")
          : pageThemeChange && pageThemeChange(undefined);
      }, 300);
    }
  };

  return (
    <>
      <PageTitle subtitle={t(`AITVStream_title`)} />
      <PageWrapper animation={pageChange}>
        {slide === 0 ? (
          <>
            {background && (
              <Banner slideChange={slideTransition}>
                <BannerBackground fluid={background.childImageSharp.fluid} />
              </Banner>
            )}
            <ContentWrapper slideChange={slideTransition}>
              <Title>{t(`AITVStream_title`)}</Title>
              <Description
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(t(`AITVStream_description`)),
                }}
              />
            </ContentWrapper>
          </>
        ) : slide === 1 ? (
          <SlideTransitionWrapper slideChange={slideTransition}>
            <Subtitle>{t(`civileo_title`)}</Subtitle>
            <Description
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(t(`AITVStream_paragraph1`)),
              }}
            />
            <Description
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(t(`AITVStream_paragraph2`)),
              }}
            />
          </SlideTransitionWrapper>
        ) : (
          slide === 2 && (
            <SlideTransitionWrapper slideChange={slideTransition}>
              {side && <SideImage fluid={side.childImageSharp.fluid} />}
              <Description
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(t(`AITVStream_paragraph4`)),
                }}
              />
            </SlideTransitionWrapper>
          )
        )}
        <TransitionWrapper animation={pageChange}>
          {transitionBlocks}
        </TransitionWrapper>
      </PageWrapper>
      <ArrowsWrapper>
        <LeftArrowWrapper
          slideChange={slide === 1 ? slideTransition : undefined}
        >
          {slide > 0 && <ArrowLeft onClick={handlePrevious} />}
        </LeftArrowWrapper>
        <RightArrowWrapper
          slideChange={slide === nOfSlides - 1 ? slideTransition : undefined}
        >
          {slide < nOfSlides && <ArrowRight onClick={handleNext} />}
        </RightArrowWrapper>
      </ArrowsWrapper>
    </>
  );
};

const imagesQuery = graphql`
  {
    background: file(
      name: { eq: "background" }
      relativeDirectory: { eq: "apps/AITVStream" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    side: file(
      name: { eq: "sideImage" }
      relativeDirectory: { eq: "apps/AITVStream" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

export default AITVStreamMobile;
